var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bcae8c7eb6a24399b99fb8507cc254e8cdc7d086"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (
  process.env.NEXT_PUBLIC_SENTRY_DSN &&
  process.env.NODE_ENV === 'production'
) {
  const environment =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? 'production'
      : process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging'
      ? 'staging'
      : 'preview';

  if (localStorage.getItem('enable-logger'))
    console.log('Sentry Environment:', environment);

  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // integrations: [new BrowserTracing()],
    tracesSampleRate: 0.05,
    environment,
  });
}
